.ui.activity-statistics.statistics > .ui.statistic {
    margin: 0;
    flex-basis: 12em;
    color: rgba(255, 255, 255, 0.5);
}

.colorchange {
    color: rgba(255, 255, 255, 0.5);
}

.ui.activity-statistics.statistics {
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
}

.ui.activity-statistics.statistics .label {
    color: rgba(255, 255, 255, 0.2);
}

.segment .ui.header.activity-name-header {
    margin-top: 0px;
}

.segment .sub.header .activity-date {
    font-weight: 600;
    color: rgba(255, 255, 255, 0.65);
}
.segment .sub.header .activity-tag {
    color: rgba(255, 255, 255, 0.65);
}

.no-uppercase {
    font-family: "Open Sans";
    font-weight: 400;
    color: "#fff"
}