.editable-section {
    position: relative;
}

.editable-section > .section-header > .header {
    margin-bottom: 0.35em;
}

.editable-section i.icon {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
}

.editable-section i.icon.hidden{
    display: none;
}

.editable-section:hover i.icon.hidden{
    display: initial;
}

.editable-section i.icon.active {
    display: initial;
    color: orange;
}

.section-header {
    width: 100%;
    display: inline-flex;
    margin-bottom: 0.5em;
}

.ui.segment + .editable-section .ui.segment.with-header {
    margin-top: 3rem !important;
}
