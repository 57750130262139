.login-panel {
    background-color: rgba(0, 0, 0, 0.75);
    width: 30%;
    min-width: 300px;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    padding: 40px 40px;

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-panel p {
    color: white;
}

#loginErrorMessage{
    color: red;
    font-weight: bold;
}