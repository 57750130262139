.bloc-container{
    display: flex;
    margin-bottom: 14px;
}
.multiplier{
    margin-left: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle{
    border-radius: 50%;
    height: 62px;
    width: 62px;
    background-color: #4F565E;
    display: flex;
    align-items: center;
    justify-content: center;
}
.multiplier h4{
    text-align: center;
    font-family: 'Open Sans', sans-serif!important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}
.reps{
    border-radius: 8.5px;
    border-collapse: separate; 
    background-color: #4F565E;
    width: 27.561%;
    min-width: 254px;
}
.line{
    position: absolute;
    width: 41px;
    height: 0px;
    left: 35px;
    z-index: 1;
    border: 1px solid #FFFFFF;
    transform: rotate(90deg);
}