.timerPosition{
    position: fixed;
    z-index: 1;
    color: #fff;
    font-size: 64px;
    top: 5vh;
    right: 5vw;
}
.guidelines{
    height: 60%;
    text-align: center;
    display: block;
}
.guidelines img{
    height: -webkit-fill-available;
}
.livegraph{
    height: 20%;
    position: fixed;
    bottom: 0;
    width: 100%;
    right: 0;
}
.livegraph canvas{
    height: 10vh;
}
.exerciseManager-container{
    height: 90vh;
}
.finishExercise {
    position: fixed;
    top: 5%;
    left: 5%;
    font-size: 64px;
    font-weight: 400;
    color: "#6d6d6d" !important;
    z-index: 3;
}
.strength{
    position: fixed;
    z-index: 1;
    color: #323232;
    font-size: 64px;
    text-align: center;
    bottom: 5vh;
    width: fit-content;
    left: 50vw;
    right: 50vw;
    transform: translate(-50%,0%);
}