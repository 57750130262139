
.backgroundPicture {
    z-index: -1;
    top:0;
    left:0;
    height: 160px !important;
    width: 100%;
    overflow: hidden;
    position: absolute;
}

.exerciseHeader {
    width: 335px;
    margin-bottom: 41px;
}

.exerciseHeader div H1{
    width: 254px;
    color: #fff;
    left: 0;
}
.exerciseHeader div body {
    width: 254px;
    font-family: open sans;
    font-weight: 300;
}
  
.exerciseButton {
    width: 196px;
    height: 40px;
    color: E74C3C;
    left: 50%;

    margin-top: 42px !important;
    display: flex;
    position: absolute;
    justify-content: center;
    align-content: center;
    transform: translate(-50%,-50%);
}

.workoutDescriptionDesktop {
    border-collapse: separate; 
    background-color: #4F565E;

    width: 100%;
    padding: 20px;
    border-radius: 8.5px;
}

.exerciseGrid {
    position: absolute;
    top: 194px;
}

.exerciseCircleMobileOutline {
    position: absolute;
    top: 16px;
    left: 19px;
    width: 2em !important;
    height: 2em !important;
}
.exerciseEditMobileOutline {
    position: absolute;
    top: 16px;
    right: 19px;
    width: 2em !important;
    height: 2em !important;
}
.exerciseDeleteMobileOutline {
    position: absolute;
    top: 46px;
    right: 19px;
    width: 2em !important;
    height: 2em !important;
}

.gridLayout {
    position: absolute;
    top: 194px;
    width: 80vw;
}

.column1 {
    width: 350px
}

.column2 {
    flex-grow: 5
}

.exerciseCircleDesktopOutline {
    position: relative;
    top: 134px;
    left: -4vw;
    width: 2em !important;
    height: 2em !important;
    z-index: 3;
}
.centerSpan{
    text-align: center;
}
.centerSpan i.icon{
    margin: 0px;
    display: unset;
}
.exerciseEditDesktopOutline {
    width: 2em !important;
    height: 2em !important;
    z-index: 3;
    margin-bottom: 16px;
    position: absolute;
    left: 93vw;
    top: 190px;
}
.exerciseDeleteDesktopOutline {
    width: 2em !important;
    height: 2em !important;
    z-index: 3;
    position: absolute;
    left: 93vw;
    top: 230px;
}
.btnContainer{
    float: right;
    margin-top: 11vh;
}
.desktopGrid{
    max-width: 1250px!important;
}

.exerciseLaunchButton {
    width: 196px;
    height: 40px;
    color: #E74C3C;
    position: fixed; 
    bottom: 0;
    left: 50%;
    z-index: 3;
}

.workoutDescriptionMobile {
    border-collapse: separate; 
    background-color: #4F565E;

    width: 100vw;
    margin: 32px -14px;
    padding: 14px;
    border-radius: 0px
}
.exerciseIcon {
    position: relative;
    margin: auto !important;
    color: #fff;
    font-size: 1.5em;
    font-weight: 900;
}

.backgroundPicture img{
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) 
}

.buttontext {
    position: relative;
    margin: auto;

    /* Button Label */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

.details-content{
    top: 30vh;
    position: absolute;
}

.details.container {
    left: 0;
    height: 60%;
    width: 100%;
}

.exerciseDescription {
    left: 10vw;
    right: 10vw;
    height: 60%;
    min-width: 80vw;
    text-align: justify;
}

.stickySection {
    position: -webkit-sticky;
    position: sticky;
    top: 50!important;
    padding: 50;
    font-size: 100;
}
.btnEdit, .btnDelete{
    position: relative;
    right: 0px;
}
.btnDelete{
    color: red!important;
}