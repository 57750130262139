.profile-top-section {
    padding: 2em 2em !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.profile-top-section img.image.profile-picture {
    width: 180px;
    height: 180px;
    margin-right: 25px;
}

.profile-top-section .user-overview-infos {
    position: relative;
    vertical-align: center;
    margin-top: 1.5em;
    margin-left: 2em;
    height: 100%;
}

.profile-top-section .user-overview-infos .stats {
    margin-top: 1.5em;
}
