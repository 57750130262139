.workout-listing .workout-preview{
    margin-right: 16px!important;
}
.btnAddWorkout{
    height: 32px!important;
    width: 32px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f3f3f3!important;
    display: flex!important;
    padding:0px!important;
    float: right;
    min-height: unset!important;
    line-height: unset!important;
    position: absolute;
    right: 7px;
}
.btnAddWorkout span{
    text-align: center;
    font-family: 'Open Sans', sans-serif!important;
    font-style: normal;
    font-weight: bold;
    font-size: 36px!important;
}
.workout-listing h2{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
.my-workouts{
    display: inline-flex;
    width: inherit;
}
.slider{
    width: auto!important;
}

.emptyMyWorkouts{
    background-color: #4F565E;
    border-radius: 8px;
    height: 66px;
}
.emptyMyWorkouts p{
    text-align: center;
    font-family: 'Open Sans', sans-serif!important;
    font-style: normal;
    font-size: 14px;
}
.emptyMyWorkouts span{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66px;
}