.message-div {
    height: 100%;
    width: 100%;
    color: white;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
}

.message-div h1 {
    font-size: 7rem;
}

.message-div p {
    font-size: 2rem;
}