.bloc-content{
    padding: 16px;
}
.centered-content{
    margin-left: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ui.tiny.image img{
    border-radius: 5px!important;
}
.ui.tiny.image{
    z-index: 2;
    width: 80px;
}

.bloc-content .header{
    color: #fff!important;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif!important;
    font-style: normal!important;
    font-weight: bold!important;
}
.bloc-content .meta{
    font-family: 'Open Sans', sans-serif!important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 8px!important;
}