.timerRawPosition{
    position: fixed;
    z-index: 1;
    color: #fff;
    font-size: 64px;
    top: 5vh;
    right: 5vw;
}

.activity-center {
    height: 85vh;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.exercise-top {
    height: 10vh;
    font-size: 14;
    color: #909090;
    justify-content: space-between;
    display: flex;
    top: 60;
    align-items: center;
    width: 100%;
}

.exercise-center {
    height: 65vh;
    display: flex;
    top: 50vh;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.exercise-bottom {
    height: 15vh;
    display: flex;
    position: absolute;
    bottom: 2vh;
    align-self: center;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin: auto;
    margin-left: auto;
    margin-right: auto;
}

.center-element {
    position: absolute;
    left: 50%;
    bottom: 3em;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.center-image {
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.exercise-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    align-items: space-between;
    justify-content: space-between;
    vertical-align: middle;
}

.menu-text {
    font-size: 14;
    color: #909090;
}

.rawStrength {
    position: fixed;
    z-index: 1;
    color: #fff;
    font-size: 64px;
    text-align: center;
    bottom: 5vh;
    left: 50vw;
    right: 50vw;
}

.inactive { color: #6d6d6d; } /* CSS link color (red) */
.link { color: #fff; transition: all .2s ease-in-out; } /* CSS link color (red) */
.link:hover { color: #C05939; transform: scale(1.1); } /* CSS link hover (green) */