.top-nav-bar-menu {
    width: 100%;
    margin-left: 10%;
    height: 30px;
}

.top-nav-bar-menu img.logo {
    margin-right: 1em !important;
}

.active {
    font-size: 10;
    font-weight: bold;
    color: #fff;
}

.inactive {
    font-size: 10;
}