.title{
    color: #323232;
    text-align: center;
}
.errorTitle{
    color: #323232;
    text-align: center;
}
.errorMessage{
    color: red;
    text-align: center;
}
.addWeightsLabel{
    background-color: #c05939 !important;
    color: #fff !important;
}
.slider{
    width: 50% !important;
}