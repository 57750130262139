.confirm-email-panel {
    background-color: rgba(0, 0, 0, 0.75);
    width: 30%;
    min-width: 300px;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    padding: 40px 40px;
}

.confirm-email-panel p {
    color: white;
    text-align: center;
}

#confirmEmailErrorMessage{
    color: red;
    font-weight: bold;
}