/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #323232;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #565656;
}

.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
 * Entralpi color scale
 */

.react-calendar-heatmap .color-entralpi-0 {
  fill: #9B9B9B;
}

.react-calendar-heatmap .color-entralpi-1 {
  fill: #D9A696;
}

.react-calendar-heatmap .color-entralpi-2 {
  fill: #CD8068;
}

.react-calendar-heatmap .color-entralpi-3 {
  fill: #C05939;
}

.react-calendar-heatmap .color-entralpi-4 {
  fill: #794636;
}

.react-calendar-heatmap .color-entralpi-5 {
  fill: #563C34;
}