.form .fields.right {
    justify-content: flex-end;
}

.flex-container {
    display: flex;
}

.flex-container.right {
    justify-content: flex-end;
}