.guidelinesText {
    transform: translate(-50%, -50%);
    font-family: "Open Sans";
    font-weight:400;
    position: fixed;
    width:40vw;
    top: 50%;
    bottom: 50%;
    text-align: "center";
    margin: 0;
    font-size: 3rem;
    transform: translate(-50%, -50%);
}

.guidelinesHands {
    transform: translate(-50%, -50%);
    font-family: "Open Sans";
    font-weight:400;
    position: fixed;
    width:40vw!important;
    top: 50%;
    bottom: 50%;
    text-align: "center";
    vertical-align: middle;
    margin: 0;
    font-size: 3rem;
    transform: translate(-50%, -50%);
}
