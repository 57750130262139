.workout-preview img{
    background-color: rgba(50, 50, 50, .65)!important;
    z-index:-1!important;
    width: 580px!important;
    height: 312px!important;
    position: absolute;
    top: -80px;
    left: -130px;
}
.workout-preview .image{
    z-index: 0;
    overflow: hidden;
    height: 66px!important;
    width: 250px!important;
}
.workout-preview .card{
    background-color: #4F565E!important;
    box-shadow: none!important;
    width: 250px!important;
    height: 146px!important;
    border-radius: 8.5px!important;
}
.workout-preview .header{
    color: #f3f3f3!important;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif!important;
    font-style: normal!important;
    font-weight: bold!important;
    margin-bottom: 8px!important;
}
.workout-preview .meta{
    font-family: 'Open Sans', sans-serif!important;
    font-style: normal;
    font-weight: lighter;
    font-size: 14px!important;
    margin-bottom: 8px!important;
}

.workout-preview .content{
    color: #f3f3f3!important;
    margin-left: 16px!important;
    padding-left: 0px!important;
    border-top: none!important;
}
.workoutDuration {
    position: absolute;
    top: 8px;
    width: 50px;
    height: 20px;
    background-color: white;
    color: #4F565E;
    text-align: center;
    border-radius: 30px;
    left: 190px;
    font-family: 'Open Sans', sans-serif!important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}
