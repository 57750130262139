

.activity-center {
    height: 85vh;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
}





.center-element {
    position: absolute;
    left: 50%;
    bottom: 3em;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}



.flappybird-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    align-items: space-between;
    justify-content: space-between;
    vertical-align: middle;
}

.menu-text {
    font-size: 14;
    color: #909090;
}


.inactive { color: #6d6d6d; } /* CSS link color (red) */
.link { color: #fff; transition: all .2s ease-in-out; } /* CSS link color (red) */
.link:hover { color: #C05939; transform: scale(1.1); } /* CSS link hover (green) */