.gym-header {
    width: 100%;
    display: inline-flex;
    align-items: baseline;
    border-bottom: 1px solid rgba(34,36,38,.15)
}

.gym-button {
    padding-bottom: 0.6em !important;
    padding-top: 0.6em !important;
    margin-left: auto !important;
}