.center {
       display: flex;
       flex-direction: column;
       text-align: center;
       align-items: center;
       justify-content: center;
}

label {
       color: #B0B0B0;
}

.form-horizontal .control-label.text-left{
       text-align: left;
}

.exercise-top {
       height: 10vh;
       font-size: 14;
       color: #909090;
       justify-content: space-between;
       display: flex;
       top: 60;
       align-items: center;
   }
   
   .exercise-selector-center {
       height: 65vh;
       display: flex;
       top: 50vh;
       align-self: center;
       align-items: center;
       justify-content: space-between;
       width: 100%;
   }
   
   .exercise-bottom {
       display: flex;
       direction: row;
       position: absolute;
       justify-content: center;
       align-content: center;
       transform: translate(-50%,-50%);
   }

.exercise-container {
       height: 100%;
       display: flex;
       flex-direction: row;
}

.menu-text {
       font-size: 14;
       color: #909090;
}

.inactive { color: hsla(0, 0%, 43%, 0.5); } /* CSS link color (red) */
.link { color: hsla(0, 0%, 95%, 0.5); transition: all .2s ease-in-out; } /* CSS link color (red) */
.link:hover { color: #C05939; transform: scale(1.1); } /* CSS link hover (green) */