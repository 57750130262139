.image-upload {
    margin: 0 0 40px 0;
}

.image-upload .input-button {
    margin-top: 20px !important;
}

.image-upload > .profile-picture {
    display: inline-block !important;
    width: 150px;
    height: 150px;
}