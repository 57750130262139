h3.segment-header {
    display: table;
    background: #C05939;
    padding: 0.5em 1em .5em;
    color: #fff;
    border-radius: 3px;
    margin: -2.25em 0 1em 0 !important;
    line-height: 1.5em;
    box-shadow: 0 4px 20px 0px rgba(39, 71, 176, 0.28)
}

.ui.segment.with-header {
    margin-top: 2rem !important;
    margin-bottom: 1rem !important;
}

.ui.segment + .ui.segment.with-header {
    margin-top: 3rem !important;
}

div + div .ui.segment.with-header {
    margin-top: 3rem !important;
}
