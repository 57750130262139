.gym-list-item.disabled {
    background-color: rgb(241, 241, 241);
}

.gym-list-item .ui.items > .item > .image.gym-picture {
    width: initial;
}

.gym-list-item .ui.items > .item > .image.gym-picture > img {
    width: 100px;
    height: 100px;
}